import './setari.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faTrash, faCheck, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';



const apiUrl = process.env.REACT_APP_API_URL;

const Setari = () => {

  const [activeMenu, setActiveMenu] = useState('personalInfo');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);


   const redirectToDashbord = useNavigate()
  const [showInfoPersMob, setShowInfoPersMob] = useState('-9');
  const funcShowInfoPersMob = () => {
        setShowInfoPersMob('-9');
    }

    const funcShowChangePass = () => {
        setShowInfoPersMob('-9');
    }

    const funcShowPlanDetailed = () => {
        setShowInfoPersMob('-9');
    }

    const funcShowCloseAccount = () => {
        setShowInfoPersMob('-9');
    }


    const [pageDashbord, setPageDashbord] = useState(true);


    // Funcția pentru a prelua datele utilizatorului
  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/user`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      }
    } catch (err) {
      console.error('Eroare la preluarea datelor utilizatorului:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

 // Funcție pentru a schimba imaginea de profil
 const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    setSelectedFile(file);
    setPreviewImage(URL.createObjectURL(file)); // Arată un preview
  }
};

// Funcție pentru a încărca imaginea pe server
const uploadProfilePicture = async () => {
  if (!selectedFile) {
    alert('Te rog să selectezi un fișier.');
    return;
  }

  const formData = new FormData();
  formData.append('profile_picture', selectedFile);

  try {
    const response = await fetch(`${apiUrl}/user/update-profile-picture`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    });

    const result = await response.json();

    if (response.ok) {
      setSuccessMessage('Imaginea a fost actualizată cu succes.');
      setUserData({ ...userData, profile_picture: result.profile_picture });
      setPreviewImage(null);
      setSelectedFile(null);
    } else {
      alert(result.message || 'Eroare la actualizarea imaginii.');
    }
  } catch (err) {
    console.error('Eroare la încărcarea imaginii:', err);
    alert('A apărut o eroare.');
  }
};


  // Funcție pentru a șterge imaginea de profil
  const handleDeleteProfilePicture = async () => {
    if (window.confirm('Ești sigur că dorești să ștergi imaginea de profil?')) {
      try {
        const response = await fetch(`${apiUrl}/user/delete-profile-picture`, {
          method: 'DELETE',
          credentials: 'include',
        });

        if (response.ok) {
          alert('Imaginea de profil a fost ștearsă.');
          fetchUserData();
        }
      } catch (err) {
        console.error('Eroare la ștergerea imaginii:', err);
      }
    }
  };

  if (loading) return <div>Se încarcă datele...</div>;
  if (!userData) return <div>Nu există date de afișat.</div>;


    const renderpageDashbord = () => {

        if (pageDashbord === 'changePass') {
                return (
                <div className='container-mob-menu-width'>
                     <div className='sub-arrow-info' onClick={funcShowChangePass}>
                        <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
                        <h4 style={{textAlign: 'left', margin: '0 auto'}}>Schimbare parola</h4>
                    </div>
                    <form className='width-submenu-elements'>
                      <label><b>Parola curenta</b></label>
                      <div className='align-passwords'>
                        <input
                          type={showCurrentPassword ? 'text' : 'password'}
                          className='set-block-password'
                          placeholder='Parola curenta'
                        />
                        <button className='show-pass-button'>
                        <FontAwesomeIcon
                          icon={showCurrentPassword ? faEyeSlash : faEye}
                          onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                          className='eye-icon'
                        />
                        </button>
                      </div>

                      <label><b>Parola noua</b></label>
                      <div className='align-passwords'>
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          className='set-block-password'
                          placeholder='Parola noua'
                        />
                        <button className='show-pass-button'>
                        <FontAwesomeIcon
                          icon={showNewPassword ? faEyeSlash : faEye}
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          className='eye-icon'
                        />
                        </button>
                      </div>

                      <input type='submit' value='Schimba parola' className='button-change-pcture' />
                    </form>
                </div>
            );
        }
            else if(pageDashbord === 'infoFact') {
                return (
                <div className='container-mob-menu-width'>
                    <div className='sub-arrow-info' onClick={funcShowPlanDetailed}>
                        <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
                        <h4 style={{textAlign: 'left', margin: '0 auto'}}>Detalii plan</h4>
                    </div>
                    <div className='width-submenu-elements-plan'>
                    <table className='plan-details-table'>
                      <tbody className='distance-table-plan'>
                        <tr>
                          <td><b>Planul curent:</b></td>
                          <td><i>PERSONAL</i></td>
                        </tr>
                        <tr>
                          <td><b>Data de start:</b></td>
                          <td><i>01/12/2024</i></td>
                        </tr>
                        <tr>
                          <td><b>Valabil pana la:</b></td>
                          <td><i>01/12/2025</i></td>
                        </tr>
                        <tr>
                          <td><b>Urmatoarea data de facturare:</b></td>
                          <td><i>02/12/2024</i></td>
                        </tr>
                        <tr>
                          <td><b>Metoda de plata:</b></td>
                          <td><i>Card</i></td>
                        </tr>
                        <tr>
                          <td><b>Descarca factura:</b></td>
                          <td>
                            <button className='button-descarca-factura'>Descarca factura</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>


                        
                       
                    </div>
                    
                </div>);
                }
                else if (pageDashbord === 'deleteAccount') {
                    // Funcția pentru a închide contul utilizatorului
                    const handleDeleteAccount = async () => {
                      if (window.confirm('Ești sigur că dorești să îți închizi contul? Această acțiune este ireversibilă.')) {
                        try {
                          const response = await fetch(`${process.env.REACT_APP_API_URL}/delete-account`, {
                            method: 'DELETE',
                            credentials: 'include'
                          });
                  
                          if (response.ok) {
                            alert('Contul tău a fost șters cu succes.');
                            window.location.href = '/'; // Redirecționează la pagina de start după ștergere
                          } else {
                            alert('A apărut o problemă la ștergerea contului. Te rugăm să încerci din nou.');
                          }
                        } catch (error) {
                          console.error('Eroare la ștergerea contului:', error);
                          alert('A apărut o eroare. Te rugăm să încerci din nou mai târziu.');
                        }
                      }
                    };
                  
                    return (
                      <div className='container-mob-menu-width'>
                        <div className='sub-arrow-info' onClick={funcShowCloseAccount}>
                          <div className='arrow-submenu-user-items'>
                            <FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' />
                          </div>
                          <h4 style={{ textAlign: 'left', margin: '0 auto' }}>Închidere cont</h4>
                        </div>
                        <div className='width-submenu-elements'>
                          <p>
                            Dacă alegi să îți închizi contul, toate informațiile tale vor fi șterse definitiv, iar accesul la contul tău nu va mai fi posibil. 
                            Această acțiune este ireversibilă, așa că te rugăm să te asiguri că dorești să continui.
                          </p>
                          <p>
                            Dacă ești sigur că dorești să îți închizi contul, apasă pe butonul de mai jos. În caz contrar, poți reveni la setări.
                          </p>
                          <button onClick={handleDeleteAccount} className='button-close-account'>
                            Închide contul
                          </button>
                        </div>
                      </div>
                    );
                  }
                  
        else {
                return (
                <div className='container-mob-menu-width'>
                    <div className='sub-arrow-info' onClick={funcShowInfoPersMob}>
                        <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
                        <h4 style={{textAlign: 'left', margin: '0 auto'}}>Informatii eprsonale</h4>
                    </div>
                    <div className='width-submenu-elements'>
                        <div className='image-profile-change'>
                            <div className='distance-image-profile'>
                            <img
                              src={previewImage || userData.profile_picture || 'https://via.placeholder.com/150'}
                              alt='Profile'
                              className='change-image-user'
        />
                            </div>
                            <div>
                                <div>JPG, GIF sau PNG. Dimensiune maxima 800K</div>
                                <div>
                                 {/* Buton pentru încărcare */}
                                  <label className='button-upload'>
                                    {selectedFile ? 'Change' : 'Upload'}
                                    <input type='file' accept='image/*' onChange={handleFileChange} style={{ display: 'none' }} />
                                  </label>

                                  {/* Buton pentru setare imagine */}
                                  {selectedFile && (
                                    <button className='button-set' onClick={uploadProfilePicture}>
                                      Set <FontAwesomeIcon icon={faCheck} />
                                    </button>
                                  )}

                                  {/* Buton pentru ștergere */}
                                  <button className='button-delete' onClick={handleDeleteProfilePicture}>
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                                {/* Mesaj de succes */}
                                {successMessage && <div className='success-message'>{successMessage}</div>}
                            </div>  
                        </div>
                      
                        <form className='form-change-image'>
                            <label className='set-block-pass-title'>Username</label>
                            <input type='text' className='set-block-pass' value={userData.username} />
                            <label className='set-block-pass-title'>Adresa de email</label>
                            <input type='email' className='set-block-pass' value={userData.email} />
                            <input type='submit' value='Salveaza' className='button-change-pcture' />
                        </form>
                    </div>

                </div>
            );
        }
        
           
    }

  return (
    <div className='settings-section'>
        <div className='settings-dashbord'>
            <div className='button-exit-change-menu-profile'>
                <div className='arrow-submenu-user-items' onClick={() => redirectToDashbord('/userpage')}><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
               

                <button className='button-edit-user-profile-desktop-show-title'>Editeaza profil</button>
                    
                <h4 className='button-edit-user-profile-desktop-hide'>Editeaza profil</h4>
            </div>
            <div className='buttons-menu-settings'>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('true'); setShowInfoPersMob('0')}}>
                    
                    <button
                      className={`button-edit-user-profile-desktop-show ${activeMenu === 'personalInfo' ? 'active' : ''}`}
                      onClick={() => setActiveMenu('personalInfo')}
                    >
                      Informatii personale
                    </button>
                    
                    <h5 className='button-edit-user-profile-desktop-hide'>Informatii personale</h5>

                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('changePass'); setShowInfoPersMob('0')}}>
                    <button
                      className={`button-edit-user-profile-desktop-show ${activeMenu === 'changePass' ? 'active' : ''}`}
                      onClick={() => setActiveMenu('changePass')}
                    >
                      Schimbare parola
                    </button>
                    <h5 className='button-edit-user-profile-desktop-hide'>Schimbare parola</h5>
                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('infoFact'); setShowInfoPersMob('0')}}>
                    <button
                      className={`button-edit-user-profile-desktop-show ${activeMenu === 'infoFact' ? 'active' : ''}`}
                      onClick={() => setActiveMenu('infoFact')}
                    >
                      Detalii plan
                    </button>
                    <h5 className='button-edit-user-profile-desktop-hide'>Detalii plan</h5>
                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('deleteAccount'); setShowInfoPersMob('0')}}>
                    <button
                      className={`button-edit-user-profile-desktop-show ${activeMenu === 'deleteAccount' ? 'active' : ''}`}
                      onClick={() => setActiveMenu('deleteAccount')}
                    >
                      Inchidere cont
                    </button>
                    <h5 className='button-edit-user-profile-desktop-hide'>Inchidere cont</h5>
                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
            </div>
        </div>
        <div className='details-settings' style={{zIndex: showInfoPersMob}} id='set-important-submenu'>
            {renderpageDashbord()}
        </div>

        <div className='details-settings' id='set-important-submenu-second'>
            {renderpageDashbord()}
        </div>
        
        
    </div>
  )
}

export default Setari;