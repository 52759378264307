import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWebAwesome, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'; // Importăm faTimes pentru X-uri
import financeAnimation from '../../imagesHome/premium.gif';
import cardOne from '../../imagesHome/cardOne.gif';
import './price.css';

const Price = () => {
  return (
    <div className='general-cards'>
      {/* Plan Gratuit */}
      <div className="item-offert">
        <div className='d-flex flex-column align-items-center'>
          <img src={cardOne} className="img-price" alt="priceimage" />
          <div className="items-card-offer">
            <h1 className='text-center fw-bold mt-4 text-title-price-free'>Gratuit</h1>
            <div className='d-flex justify-content-between'>
              <div className='mx-3'>
              <div className='item-space-offer'>
                  📘 acces la lectiile de baza
              </div>
              <div className='item-space-offer'>
                  🛠️ acces la lectiile de practica
              </div>
              <div className='item-space-offer'>
                  🎯 acces la teste de final de capitol
              </div>
              <div className='item-space-offer'>
                  🎓 certificat de finalizare a cursului
              </div>

              </div>
              <div className='mx-2'>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faTimes} className='item-x' /></div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/registrer">
          <button className='button-offer'>CREAZA CONT</button>
        </Link>
      </div>

      {/* Plan Premium */}
      <div className="item-offert">
        <div className='d-flex flex-column align-items-center'>
          <img src={financeAnimation} className="img-price" alt="priceimage" />
          <div className="items-card-offer">
            <h1 className='text-center fw-bold mt-4 text-title-price-premium'>Premium</h1>
            <div className='d-flex justify-content-between'>
              <div className='mx-3'>
              <div className='item-space-offer'>
                  📘 acces la lectiile de baza
              </div>
              <div className='item-space-offer'>
                  🛠️ acces la lectiile de practica
              </div>
              <div className='item-space-offer'>
                  🎯 acces la teste de final de capitol
              </div>
              <div className='item-space-offer'>
                  🎓 certificat de finalizare a cursului
              </div>

              </div>
              <div className='mx-2'>
                {/* Bife pentru toate avantajele */}
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
                <div className='item-space-offer'><FontAwesomeIcon icon={faCheck} className='item-bifa' /></div>
              </div>
            </div>
          </div>
        </div>
        <p>Incearca <b>7 zile GRATUIT</b></p>
        <Link to="/registrer">
          <button className='button-offer-premium'>
            <FontAwesomeIcon icon={faWebAwesome} className='mx-1' />
            START
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Price;
