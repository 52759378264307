import calculator from '../imagesHome/calculator.png';
import accounting from '../imagesHome/accounting.gif';
import exam from '../imagesHome/exam.gif';
import financialmanagement from '../imagesHome/financial-management.gif';
import financeanimation from '../imagesHome/finance-animation.gif';
import contafinanciara from '../imagesHome/conta-financiara.gif';






import './Presentation.css';
import { Link } from 'react-router-dom';


export const Presentation = () => {
  return (
    <div className='container-presentation'>
        <div className='first-presentation'>
            <img src={calculator} alt='calculator' />
            <div className='text-button-presentation'>
                <h1 className='color-presentation'>Invata contabilitate de la 0</h1>
                <Link to='./registrer'>
                    <button className='buttons-presentation'>Incepe astazi!</button>
                </Link>
                <Link to='login'>
                    <button className='buttons-presentation-login'>Am deja cont!</button>
                </Link>
            </div>
        </div>

        

       


        

        <div className='colored-zone-presentation'>
            <div className='first-presentation'>
                
                <div className='text-button-presentation'>
                    <h1 className='color-presentation-2 my-3 text-white'>Se apropie sesiunea si ai nevoie sa inveti rapid contabilitate?</h1>
                    <p className='text-presentation-1 my-3'>
                        <p><b>📚 Nu te stresa!</b></p>
                        <p><b>✅Acum contabilitatea poate fi invatata usor.</b></p>
                        <p>Acum poti invata conceptele de baza ale contabilitatii, cat si conceptele avansate intr-un timp record, indiferent daca esti student sau vrei sa iti imbunatatesti cunostintele!</p>
                        <p>Cursurile interactive sunt gandite special pentru tine:</p>
                        <p>✔ <b>explicatii clare</b> si exemple practice pentru o invatare eficienta;</p>
                        <p>✔ <b>intrebari simple</b> cu variante de raspuns;</p>
                        <p>✔ <b>lectii speciale de practica</b> pentru a-ti fixa cunostintele;</p>
                        <p>✔ <b>test de evaluare</b> dupa fiecare final de capitol;</p>
                        <p>✔ <b>certificat de absolvire</b> pentru fiecare curs in parte.</p>
                        <p><b>⏰ Nu mai pierde timpul!</b></p> 
                        <p>Incepe acum sa inveti si fii pregatit pentru examene sau pentru provocarile din cariera ta!</p>
                    </p>



</div>
                <img src={exam} alt='exam' />
            </div>
        </div>

       

        <div className='first-presentation-second'>
            <img src={accounting} alt='accounting' />
            <div className='text-button-presentation'>
                
                <p className='text-presentation-1'>
    <h2><b>📘 Bazele contabilitatii</b></h2>
    

    <p>Acest curs este destinat celor care doresc sa inteleaga elementele de baza ale contabilitatii. Vei descoperi conceptele fundamentale, regulile de functionare a conturilor si modul in care contabilitatea contribuie la gestionarea eficienta a resurselor economice.</p>

    
    <p><b>Ce vei gasi in acest curs?</b></p>
        <ul>
            <p>✔ Lectii bine structurate care acopera conceptele cheie.</p>
            <p>✔ Intrebari interactive pentru fiecare capitol, menite sa te ajute sa iti fixezi cunostintele.</p>
            <p>✔ Lectii practice pentru a aplica ceea ce ai invatat.</p>
            <p>✔ Test de evaluare la finalul fiecarui capitol, pentru a-ti verifica progresul.</p>
            <p>✔ Certificat de finalizare la terminarea cursului, care atesta competentele dobandite.</p>
        </ul>
    </p>
    <Link to='./registrer'>
        <button className='buttons-presentation'>Start</button>
    </Link>


            </div>
        </div>

        <div className='betweeb-line-presentation'></div>

        <div className='first-presentation-conta-financiara'>
           
            <div className='text-button-presentation'>
               
                <p className='text-presentation-1'>
                    <h2><b>Contabilitate financiara</b></h2>
                    
                    <p>Acest curs iti ofera o intelegere avansata a contabilitatii financiare, de la inregistrarile contabile si operatiunile economice pana la intocmirea balantei de verificare. Ideal pentru cei care doresc sa aprofundeze si sa aplice cunostintele in practica.</p>
                    
                    <p><b>Ce vei gasi in acest curs?</b></p>
                    <ul>
                        <p>✔ Lectii bine structurate care acopera conceptele cheie.</p>
                        <p>✔ Intrebari interactive pentru fiecare capitol, menite sa te ajute sa iti fixezi cunostintele.</p>
                        <p>✔ Lectii practice pentru a aplica ceea ce ai invatat.</p>
                        <p>✔ Teste de evaluare pentru fiecare capitol, astfel incat sa-ti monitorizezi progresul.</p>
                        <p>✔ Certificat de finalizare la finalul cursului, recunoscut pentru competentele dobandite.</p>
                    </ul>
                </p>
                <Link to='./registrer'>
                    <button className='buttons-presentation'>Start</button>
                </Link>


            </div>
            <img src={contafinanciara} alt='accounting' />
        </div>

      


        <div className='betweeb-line-presentation'></div>
        
        <div className='background-part-presentation'>
        <h1 className='inter-text-presentation'>Invata contabilitate de oriunde</h1>
        <img src={financeanimation} alt='financeanimation' />
        <Link to='./registrer'>
            <button className='buttons-presentation fs-5 py-3'>Incearca 7 zile gratuit!</button>
        </Link>
        </div>
        <div className='betweeb-line-presentation'></div>


        <div className='third-part'>
            <h1 className='color-presentation-1'>Invata contabilitate cu ajutorul ContaLearn</h1>
            
            <div className='text-button-presentation'>
                <Link>
                    <button className='buttons-presentation-login'>Start</button>
                </Link>
            </div>

          
            <div className='align-footer-presentation'>
                <img src={financialmanagement} alt='financialmanagement' />
            </div>
       

        </div>

       
        


      
    </div>
  )
}


